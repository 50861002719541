import { createContext, useState } from "react";
import { Container, Row, Col, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useMain } from "../hooks/useMain";
import { 
  ArrowClockwise
} from 'react-bootstrap-icons';


export const global = {
    domainBack : 'https://sypd.anitacode.com/back-end/public',
    domainApi : 'https://sypd.anitacode.com/back-end/public/index.php/api/'
  }

  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

  export const toURI = ( value ) =>{
    value = value.toLowerCase();
    value = value.replace(/\s/g, '-');
    value = removeAccents(value);
    return value;
  }
export const Loader = () => <div className='w-100 text-center py-3'><img alt={'loader'} src={global.domainBack + "/img/Ripple-2s-167px1.svg"} /></div>;


export const Layout = ( props ) =>{
  const [main , setMain ] = useState(null)
  const mainWait = useMain();
  mainWait.then((value) => setMain(value));
  
  return (
    <>
      {
        main !== null ?
        <div className="maincontent bgblue">
          <div className="bg-white">
            <header className="bggolden pb-1">
              <div className="header_container bg-white">
                <Container>
                    <Row className="no-gutters">
                        <Col>
                            <div className="d-flex justify-content-between align-items-center">
                                <a className="fadeoutcontent" href="/">
                                    <img className="my-2" src={global.domainBack + main.admin.logo_negative } height="120" />
                                </a>
                                <Navbar className="mainnav" bg="transparent" variant="light">
                                  <Container>
                                    <Nav className="me-auto">
                                      <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                      <NavDropdown title="About" id="about-nav-dropdown" drop={'down'}>
                                        <NavDropdown.Item href="/about">About</NavDropdown.Item>
                                        <NavDropdown.Item href="/contact">Contact</NavDropdown.Item>
                                      </NavDropdown>
                                      <Nav.Link href="/#projects">Projects</Nav.Link>
                                      <Nav.Link href="/#whysypd">Build with us</Nav.Link>
                                      <Nav.Link href="/#financing">Financing</Nav.Link>
                                      <Nav.Link href="/architecture">Drafting services</Nav.Link>
                                    </Nav>
                                  </Container>
                                </Navbar>
                            </div>
                        </Col>
                    </Row>                    
                </Container>
              </div>
            </header>
            <main>
              { props.children }
            </main>
            <footer className="footer page-footer bg-white">
              <div className="bggolden pt-1"></div>
              <div className="footer_container py-3">
                <Container>
                  <Row className={'align-items-center'}>
                    <Col xs={12} md={4} className="text-center">
                        <h5 className="font">Office Hours</h5>
                        <p className="mt-4">{main.admin.schedule}</p>
                    </Col>
                    <Col className="text-center" xs={12} md={4}>
                        <img className="my-2" src={global.domainBack + main.admin.logo_negative} height="130" />
                        <div>
                            <a target="_blank" href={main.admin.facebooklink} className="fb-ic align-items-center">
                                
                            </a>
                            <a target="_blank" href={main.admin.instagramlink} className="ins-ic align-items-center">
                              
                            </a>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <h5 className="font">Address</h5>
                      <p className="mt-4" dangerouslySetInnerHTML={{__html: main.admin.address }}>
                      
                      </p>
                    </Col>
                  </Row>
                </Container>
              </div>
            </footer>
          </div>
        </div>
        :
        <Loader/>
      }
    </>
  );
}  
  
export const GlobalContext = createContext(global);