import { useState } from 'react';

export const useMessage = () => {
    const [ message, setInnerMessage ] = useState(localStorage.getItem('message'));
    
    const setMessageMain = ( value ) =>{
        setInnerMessage(value);
        localStorage.setItem('message',value);
    }

    const deleteMessage = () => {
        setInnerMessage(null);
        localStorage.removeItem('message');
    }
    
    return [ message, setMessageMain, deleteMessage ];
}