import { useState, useContext } from 'react';
import { global, GlobalContext } from '../utils/general';
import { useMessage } from './useMessage';
import axios from 'axios';

export const useMain = async () => {
    const [ main, setInnerMain ] = useState(JSON.parse(localStorage.getItem('main')));
    const [ , setMessageMain, deleteMessage ] = useMessage();

    const fetchMain = async () =>{
        let url = global.domainApi + 'main';   
        let response = await axios.get(url);
        if(response.status === 200){
            deleteMessage();
            setInnerMain(response.data);
            localStorage.setItem('main', JSON.stringify(response.data));
        } else {
            setMessageMain('Error Loading Main Data');
        }
        return true;
    }

    if(main === null){
        await fetchMain();
    }

    return main;
}