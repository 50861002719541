import { Suspense } from 'react';
import { useMain } from './hooks/useMain';
import { useMessage } from './hooks/useMessage';
import { GlobalContext, Layout, Loader } from './utils/general';
import { Alert, Container } from 'react-bootstrap';
import { RouteRenderer } from 'yarr';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';



function App() {
  const [ message ] = useMessage();

  return (
    <GlobalContext.Provider value={GlobalContext}>
        <>
          <Container fluid>
            {message !== null && <Alert className={'my-3'}>{message}</Alert>}
          </Container>
          <Layout>
            <Suspense fallback={'...loading'}>
              <RouteRenderer
                  pendingIndicator={<Loader />}
                  routeWrapper={({ Route }) => {
                      return <>
                        <div className="route">{Route}</div>
                      </>
                    }}
                />
            </Suspense>
          </Layout>

        </>
    </GlobalContext.Provider>
  );
}

export default App;
