export const routes = [
    {
      component: async () => {
        const module = await import('../pages/Home');
        return module.Home;
      },
      path: '/',
    },
    {
      component: async () => {
        const module = await import('../pages/About');
        return module.About;
      },
      path: '/about',
    },
    {
      component: async () => {
        const module = await import('../pages/Contact');
        return module.Contact;
      },
      path: '/contact',
    },
    {
      component: async () => {
        const module = await import('../pages/Architecture');
        return module.Architecture;
      },
      path: '/architecture',
    },
    {
      component: async () => {
        const module = await import('../pages/Team');
        return module.Team;
      },
      path: '/officers/:alias-:id',
    },
    {
      component: async () => {
        const module = await import('../pages/NotFound');
  
        return module.NotFound;
      },
      path: '*',
    },
  ];
  