import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { RouterProvider, createBrowserRouter } from 'yarr';

import { routes } from './utils/routes';

const router = createBrowserRouter({
  routes,
});

ReactDOM.render(
  <RouterProvider router={router}>
    <App />
  </RouterProvider>,
  document.getElementById('root')
);

